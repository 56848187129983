import { useNavigate, Router as R, useSearchParams, useLocation } from "@solidjs/router";
import { createComputed, createEffect, splitProps, untrack } from "solid-js";
import { $route, $route_params, triggers_funcs } from "../SHARED/store";
import type { MainProps } from "./_model";

export default function Router(props: MainProps) {
  const [local, others] = splitProps(props, ["children"]);
  let after_navigate_funcs = undefined as (() => void)[];
  return (
    <R
      {...others}
      // preload={false}
      root={(props) => {
        const $location = useLocation();
        const navigate = useNavigate();
        const [$search_params, $params_actions] = useSearchParams();
        createEffect(() => {
          const data = $route.accessor();
          // console.log("navigation requested :: ", data);
          untrack(() => {
            // const [my_options, navigate_options] = splitProps(data.options, ["afterNavigate"]);
            // console.log("router myoptions :: ", my_options, navigate_options);
            if (data) {
              // console.log("sad ::: ", unwrap($search_params));
              if (data.delta) {
                navigate(data.delta);
              } else {
                navigate(data.to, data.options);
              }
              if (!after_navigate_funcs) after_navigate_funcs = [];
              if (data.options?.afterNavigate) after_navigate_funcs.push(data.options?.afterNavigate);
              // $route.set(undefined);
            }
          });
        });

        createEffect(() => {
          const data = $route_params.accessor();
          untrack(() => {
            if (data) {
              // console.log("data from log is :: ", data);
              $params_actions(data.params, data.options);
              // $route_params.set(undefined);
            }
          });
        });
        createEffect(() => {
          const { pathname } = $location;
          console.log(`// NAVIGATION OCCURED TO ${pathname} \\\\`);
          untrack(() => {
            after_navigate_funcs?.forEach((f) => untrack(() => f()));
            triggers_funcs.on_navigated.forEach((f) => untrack(() => f()));
            after_navigate_funcs = undefined;
          });
        });

        return others?.root?.(props) ?? props.children;
      }}
    >
      {/* <Route
        path="*"
        component={(props) => {
          console.log("setup root of root");
          return <></>;
        }}
      /> */}
      {local.children}
    </R>
  );
}
